import {
  LIST_TIPOS_DASH_INIT,
  LIST_TIPOS_DASH_SUCCESS,
  LIST_CATEGORIA_DASH_INIT,
  LIST_CATEGORIA_DASH_SUCCESS,
  LIST_MY_DASHS_INIT,
  LIST_MY_DASHS_SUCCESS,
  DASH_ERROR_MSG,
  CLEAR_DASH,
  SEND_USING_DASH_TIMESTAMP
} from '../actionTypes';

export const listTiposDashInit = () => ({
  type: LIST_TIPOS_DASH_INIT,
  payload: {}
});

export const listTiposDashSuccess = ({ idTipos, tiposDash }) => {
  return {
    type: LIST_TIPOS_DASH_SUCCESS,
    payload: { idTipos, tiposDash }
  };
};

export const listCategoriaDashInit = () => ({
  type: LIST_CATEGORIA_DASH_INIT,
  payload: {}
});

export const listCategoriaDashSuccess = ({ idCategoria, categorias }) => {
  return {
    type: LIST_CATEGORIA_DASH_SUCCESS,
    payload: { idCategoria, categorias }
  };
};
export const listMyDashsInit = ({ mUser, empId, userId }) => ({
  type: LIST_MY_DASHS_INIT,
  payload: { mUser, empId, userId }
});

export const listMyDashsSuccess = ({ dashs }) => {
  return {
    type: LIST_MY_DASHS_SUCCESS,
    payload: { dashs }
  };
};
export const dashErrorMsg = (data) => ({
  type: DASH_ERROR_MSG,
  payload: data
});

export const clearDash = (data) => ({
  type: CLEAR_DASH,
  payload: data
});

export const sendUsingDashTimestamp = (data) => ({
  type: SEND_USING_DASH_TIMESTAMP,
  payload: data
});
