import { combineReducers } from 'redux';
import accountReducer from './auth/reducer';
import dashsReducer from './dashs/reducer';

const reducers = combineReducers({
  account: accountReducer,
  dashs: dashsReducer
});

export default reducers;
