import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ObjectID } from 'bson';
import {
  LIST_TIPOS_DASH_INIT,
  LIST_CATEGORIA_DASH_INIT,
  LIST_MY_DASHS_INIT,
  SEND_USING_DASH_TIMESTAMP
} from '../actionTypes';
import {
  listTiposDashSuccess,
  listCategoriaDashSuccess,
  listMyDashsSuccess,
  dashErrorMsg
} from './actions';
import {
  dashTypes,
  categorias,
  mongoGetMyDashs,
  mongoAccessDashboard
} from '../../api/mongoRealm';

// ------------------------- Comumn Functions --------------------------------------------------------
const listInfoDashAsync = async ({ collection, option = 'tipos' }) =>
  collection
    .findOne()
    .then((result) => {
      if (result === 'null') {
        return [];
      }
      const { _id } = result;
      const info = result[option];
      const infoDash = {
        id: _id.toString(),
        info,
        isReceived: true
      };
      return infoDash;
    })
    .catch((error) => {
      console.error(error);
      return { id: null, tipos: [], isReceived: false };
    });
// -------------------Listar Tipos da Dash-----------------------------

function* listTiposDashMiddle({ payload }) {
  try {
    const { id, info, isReceived } = yield call(listInfoDashAsync, {
      collection: dashTypes,
      option: 'tipos'
    });
    if (isReceived) {
      yield put(listTiposDashSuccess({ idTipos: id, tiposDash: info }));
    } else {
      yield put(
        dashErrorMsg({ message: 'Erro ao receber tipos de Daashboards' })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(dashErrorMsg(error));
  }
}

export function* watchListTiposDash() {
  yield takeEvery(LIST_TIPOS_DASH_INIT, listTiposDashMiddle);
}

// -------------------Listar Tipos da Dash-----------------------------

function* listCategoriaDashMiddle({ payload }) {
  try {
    const { id, info, isReceived } = yield call(listInfoDashAsync, {
      collection: categorias,
      option: 'categorias'
    });
    if (isReceived) {
      yield put(
        listCategoriaDashSuccess({ idCategoria: id, categorias: info })
      );
    } else {
      yield put(
        dashErrorMsg({ message: 'Erro ao receber tipos de Daashboards' })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(dashErrorMsg(error));
  }
}

export function* watchListCategoriasDash() {
  yield takeEvery(LIST_CATEGORIA_DASH_INIT, listCategoriaDashMiddle);
}
// -------------------Listar Dashboards-----------------------------

const getMyDashsMongoDB = async ({ mongoUser, empId, userId }) => {
  try {
    const myDashsData = await mongoGetMyDashs(mongoUser.user, empId, userId);
    return { isReceived: true, data: myDashsData };
  } catch (error) {
    console.error(error);
    return {
      isReceived: false,
      data: null
    };
  }
};

function* listDashMiddle({ payload }) {
  const { mUser, empId, userId } = payload;
  try {
    const { data, isReceived } = yield call(getMyDashsMongoDB, {
      mongoUser: mUser,
      empId,
      userId
    });
    if (isReceived) {
      yield put(listMyDashsSuccess({ dashs: data }));
    } else {
      yield put(
        dashErrorMsg({ message: 'Erro ao receber tipos de Daashboards' })
      );
    }
  } catch (error) {
    console.error(error);
    yield put(dashErrorMsg(error));
  }
}

export function* watchListDash() {
  yield takeEvery(LIST_MY_DASHS_INIT, listDashMiddle);
}
// ---------------- Send LogAcess to mongo -------------------------------
// ------------------------------------------------------------------------------------

const sendUsingDashTimestampAsync = async ({ mongoUser, logData }) =>
  mongoAccessDashboard(mongoUser.user, logData)
    .then((result) => {
      return { flag: true, message: '' };
    })
    .catch((error) => {
      return { flag: false, message: error };
    });

function* sendUsingDashTimestampWithData({ payload }) {
  const { userId, timestamp, dashId, empId, mongoUser } = payload;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const logData = {
    empId: empId,
    userId: userId,
    timestamp: timestamp,
    date: today.toISOString(),
    dashId: dashId
  };
  try {
    const logSend = yield call(sendUsingDashTimestampAsync, {
      mongoUser,
      logData
    });
    if (logSend.flag) {
      console.log('Auth Log Enviado');
    } else {
      console.log('Erro ao enviar log para o servidor', logSend.message);
    }
  } catch (error) {
    console.log(error);
    yield put(dashErrorMsg(error));
  }
}
export function* watchSendUsingDashTimestamp() {
  yield takeEvery(SEND_USING_DASH_TIMESTAMP, sendUsingDashTimestampWithData);
}

// WATCHS
export default function* rootSaga() {
  yield all([
    fork(watchListTiposDash),
    fork(watchListCategoriasDash),
    fork(watchListDash),
    fork(watchSendUsingDashTimestamp)
  ]);
}
