// Auth action type

export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const USER_DATA_INIT = 'USER_DATA_INIT';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const GET_AUTH_CLAIMS = 'GET_AUTH_CLAIMS';
export const AUTH_INIT = 'AUTH_INIT';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const CLEAR_USER_AUTH = 'CLEAR_USER_AUTH';
export const GET_EMPRESA_INIT = 'GET_EMPRESA_INIT';
export const GET_EMPRESA_SUCCESS = 'GET_EMPRESA_SUCCESS';
export const GET_MY_EMPRESA_ERROR = 'GET_MY_EMPRESA_ERROR';
// -------------------

// Dashboards action types
export const LIST_TIPOS_DASH_INIT = 'LIST_TIPOS_DASH_INIT';
export const LIST_TIPOS_DASH_SUCCESS = 'LIST_TIPOS_DASH_SUCCESS';
export const LIST_CATEGORIA_DASH_INIT = 'LIST_CATEGORIA_DASH_INIT';
export const LIST_CATEGORIA_DASH_SUCCESS = 'LIST_CATEGORIA_DASH_SUCCESS';
export const DASH_ERROR_MSG = 'DASH_ERROR_MSG';
export const LIST_MY_DASHS_INIT = 'LIST_MY_DASHS_INIT';
export const LIST_MY_DASHS_SUCCESS = 'LIST_MY_DASHS_SUCCESS';
export const CLEAR_DASH = 'CLEAR_DASH';
export const SEND_USING_DASH_TIMESTAMP = 'SEND_USING_DASH_TIMESTAMP';
