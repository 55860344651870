import { ReactComponent as AddChartIcon } from './svg/addChart.svg';
import { ReactComponent as DoneAllIcon } from './svg/doneAll.svg';
import { ReactComponent as FactCheckIcon } from './svg/factCheck.svg';
import { ReactComponent as PermIdentityIcon } from './svg/permIdentity.svg';
import { ReactComponent as AnaliseDeCrescimento } from './svg/analiseDeCrescimento.svg';
import { ReactComponent as CategoriaCliente } from './svg/categoriaCliente.svg';
import { ReactComponent as ControleDeCompras } from './svg/controleDeCompras.svg';
import { ReactComponent as CurvaABC } from './svg/curvaABC.svg';
import { ReactComponent as Desconto } from './svg/desconto.svg';
import { ReactComponent as Fidelizacao } from './svg/fidelizacao.svg';
import { ReactComponent as FluxoDeVendas } from './svg/fluxoDeVendas.svg';
import { ReactComponent as IndicadoresDeEficiencia } from './svg/indicadoresDeEficiencia.svg';
import { ReactComponent as MargemDeLucro } from './svg/margemDeLucro.svg';
import { ReactComponent as MetasIndividuais } from './svg/metasIndividuais.svg';
import { ReactComponent as PanoramaDeCadastros } from './svg/panoramaDeCadastros.svg';
import { ReactComponent as PanoramaDeVendas } from './svg/panoramaDeVendas.svg';
import { ReactComponent as PoderDeEconomia } from './svg/poderDeEconomia.svg';
import { ReactComponent as Premiacao } from './svg/premiacao.svg';
import { ReactComponent as Rupturas } from './svg/rupturas.svg';
import { ReactComponent as CrescimentoDetalhado } from './svg/crescimentoDetalhado.svg';
import { ReactComponent as CrescimentoResumido } from './svg/crescimentoResumido.svg';
import { ReactComponent as IconeCaterogiaCliente } from './svg/iconeCategoriaCliente.svg';
import { ReactComponent as RetratoDasVendas } from './svg/retratoDasVendas.svg';
import { ReactComponent as VendasDetalhadas } from './svg/vendasDetalhadas.svg';
import { ReactComponent as MetasGerais } from './svg/metasGerais.svg';

const svgIcons: any = {
  AddChartIcon: AddChartIcon,
  DoneAllIcon: DoneAllIcon,
  FactCheckIcon: FactCheckIcon,
  PermIdentityIcon: PermIdentityIcon,
  AnaliseDeCrescimento: AnaliseDeCrescimento,
  CategoriaCliente: CategoriaCliente,
  ControleDeCompras: ControleDeCompras,
  CrescimentoDetalhado: CrescimentoDetalhado,
  CrescimentoResumido: CrescimentoResumido,
  CurvaABC: CurvaABC,
  Desconto: Desconto,
  Fidelizacao: Fidelizacao,
  FluxoDeVendas: FluxoDeVendas,
  IconeCaterogiaCliente: IconeCaterogiaCliente,
  IndicadoresDeEficiencia: IndicadoresDeEficiencia,
  MargemDeLucro: MargemDeLucro,
  MetasGerais: MetasGerais,
  MetasIndividuais: MetasIndividuais,
  PanoramaDeCadastros: PanoramaDeCadastros,
  PanoramaDeVendas: PanoramaDeVendas,
  PoderDeEconomia: PoderDeEconomia,
  Premiacao: Premiacao,
  RetratoDasVendas: RetratoDasVendas,
  Rupturas: Rupturas,
  VendasDetalhadas: VendasDetalhadas
};
export default svgIcons;
