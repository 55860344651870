import app from './app';
import { mongoDBName } from '../../config';

const mongodb = mongoDBName as string;
// TODO: Initialize a MongoDB Service Cliente
const mongo = app.currentUser?.mongoClient('mongodb-atlas');
// TODO: Instantiate a collection handle for todo.items
const empresas = mongo?.db(mongodb).collection('empresas');
const users = mongo?.db(mongodb).collection('users');
const dashTypes = mongo?.db(mongodb).collection('dashTypes');
const categorias = mongo?.db(mongodb).collection('categorias');

export { empresas, users, dashTypes, categorias };
