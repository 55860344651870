import * as Realm from 'realm-web';

export const mongoGetUserData = async (
  user: Realm.User,
  userId: string
): Promise<any> => {
  const result: any = await user.callFunction('getMyUserData', userId);
  return result;
};

export const mongoGetEmp = async (
  user: Realm.User,
  empId: string
): Promise<any> => {
  const result: any = await user.callFunction('getMyEmp', empId);
  return result;
};

export const mongoAccessDashboard = (
  user: Realm.User,
  data: any
): Promise<any> => {
  const dataResult = user.callFunction('countAccessDashboard', data);
  return dataResult;
};

export const mongoGetMyDashs = async (
  user: Realm.User,
  empId: string,
  userId: string
): Promise<boolean> => {
  const result: any = await user.callFunction('getMyDashs', empId, userId);
  return result;
};
