import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  makeStyles,
  Button
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from '../../../hooks/useSettings';
import { THEMES } from '../../../constants';
import useAuth from '../../../hooks/useAuth';
import { privileges } from '../../../config';
import { urlGestor } from '../../../config';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  formLabel: {
    color: theme.palette.primary.black
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { user } = useAuth();
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };
  const preventDefault = (event) => event.preventDefault();
  return (
    <>
      {/* {String(user.privilegeHash) === privileges.gestorLocal && (
        <Button variant="contained" color="primary" href={urlGestor}>
          Modo Visual
        </Button>
      )} */}
    </>
  );
};

export default Settings;
