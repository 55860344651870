import {
  AUTH_STATE_CHANGED,
  USER_DATA_INIT,
  USER_DATA_SUCCESS,
  AUTH_INIT,
  CLEAR_USER_AUTH,
  GET_EMPRESA_INIT,
  GET_EMPRESA_SUCCESS
} from '../actionTypes';

const INIT_STATE = {
  isAuthenticated: false,
  isInitialised: false,
  loading: false,
  user: null,
  myEmp: null,
  empId: '',
  isReceived: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_STATE_CHANGED: {
      const { isAuthenticated, user, loading, myEmp, empId } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        loading,
        user,
        myEmp,
        empId
      };
    }
    case USER_DATA_INIT:
      return { ...state, loading: true, dataRequested: true, error: '' };
    case AUTH_INIT:
      return { ...state, loading: true, error: '' };
    case USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          data: {
            ...state.user.data
          }
        },
        error: ''
      };
    case GET_EMPRESA_INIT: {
      const { loading, empId } = action.payload;
      return {
        ...state,
        loading: true,
        isReceived: false,
        empId
      };
    }
    case GET_EMPRESA_SUCCESS: {
      const { myEmp } = action.payload;
      return {
        ...state,
        loading: false,
        isReceived: true,
        myEmp: myEmp
      };
    }
    case CLEAR_USER_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        isInitialised: false,
        user: null,
        myEmp: null,
        empId: '',
        isReceived: false
      };
    default:
      return { ...state };
  }
};
