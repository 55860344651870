import {
  LIST_TIPOS_DASH_INIT,
  LIST_TIPOS_DASH_SUCCESS,
  DASH_ERROR_MSG,
  LIST_CATEGORIA_DASH_INIT,
  LIST_CATEGORIA_DASH_SUCCESS,
  LIST_MY_DASHS_INIT,
  LIST_MY_DASHS_SUCCESS,
  CLEAR_DASH,
  SEND_USING_DASH_TIMESTAMP
} from '../actionTypes';

const INIT_STATE = {
  loading: false,
  isUpdated: false,
  tiposDash: [],
  categorias: [],
  dashs: [],
  idTipos: '',
  idCategoria: '',
  msg: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DASH_ERROR_MSG: {
      const { msg } = action.payload;
      return { ...state, loading: false, msg };
    }
    case LIST_TIPOS_DASH_INIT:
      return { ...state, loading: true, isUpdated: false };

    case LIST_TIPOS_DASH_SUCCESS: {
      const { tiposDash, idTipos } = action.payload;
      return { ...state, tiposDash, idTipos, isUpdated: true, loading: false };
    }
    case LIST_CATEGORIA_DASH_INIT:
      return { ...state, loading: true, isUpdated: false };

    case LIST_CATEGORIA_DASH_SUCCESS: {
      const { categorias, idCategoria } = action.payload;
      return {
        ...state,
        categorias,
        idCategoria,
        isUpdated: true,
        loading: false
      };
    }
    case LIST_MY_DASHS_INIT:
      return { ...state, loading: true, isReceived: false };

    case LIST_MY_DASHS_SUCCESS: {
      const { dashs } = action.payload;
      return {
        ...state,
        dashs,
        isReceived: true,
        loading: false
      };
    }
    case CLEAR_DASH: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    case SEND_USING_DASH_TIMESTAMP:
      return { ...state };

    default:
      return { ...state };
  }
};
