import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import RoutesByRole from './utils/rbac/RoutesByRole';

interface RoutesList {
  exact?: boolean;
  path?: string;
  component?: any;
  routes?: Array<RoutesList>;
  guard?: any;
  layout?: any;
  allowedRoles?: string[];
}
export const renderRoutes = (routes: Array<RoutesList> = []): unknown => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <RoutesByRole
            // eslint-disable-next-line react/no-array-index-key
            key={`${route.path} ${i}`}
            path={route.path}
            exact={route.exact}
            allowedRoles={route.allowedRoles}
            render={(props): JSX.Element => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView'))
  },
  {
    // rota empresa inativa
    exact: true,
    guard: AuthGuard,
    path: '/empresa-inativa',
    component: lazy(() => import('./views/errors/EmpresaInativa'))
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/Home'))
      },
      {
        exact: true,
        path: '/conta',
        component: lazy(() => import('./views/account/AccountView'))
      },
      {
        exact: true,
        path: '/dashboard/:empId/:categoriaId/:dashId',
        component: lazy(() => import('./views/dashboards/DashView'))
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
