import React from 'react';
import { SvgIcon } from '@material-ui/core';

const objIcons = (array: any[], key: string, svgIconsObj: any): any => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: <SvgIcon component={svgIconsObj[item[key]]} />
    };
  }, initialValue);
};

export default objIcons;
