import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { mongoGetUserData, mongoGetEmp } from '../../api/mongoRealm';
import {
  USER_DATA_INIT,
  AUTH_STATE_CHANGED,
  GET_EMPRESA_INIT
} from '../actionTypes';
import {
  userDataSuccess,
  authStateChanged,
  getUserError,
  getEmpresaSuccess
} from './actions';
import {
  listTiposDashInit,
  listCategoriaDashInit,
  listMyDashsInit
} from '../dashs/actions';
// -----------------------------------------------------------
const getUserMongoDB = async ({
  mongoUser,
  empId,
  isAdmin,
  privilege,
  privilegeHash,
  firebaseId,
  photoURL,
  email,
  name
}) => {
  const userData = {
    id: firebaseId,
    empId: empId,
    mongoUser: mongoUser.logged ? mongoUser : null,
    avatar: photoURL || '',
    email: email || '',
    name: name || email || ' ',
    myEmp: {},
    isAdmin,
    privilege,
    privilegeHash
  };
  try {
    const myEmpData = await mongoGetEmp(mongoUser.user, empId);
    const { data, isFinded } = await mongoGetUserData(mongoUser.user);
    const {
      _id,
      status,
      lojas,
      dashboards,
      groupControl,
      grupos,
      indelible,
      config
    } = myEmpData;
    if (isFinded) {
      const { loja, erpId, job } = data;
      userData.myLoja = loja;
      userData.erpId = erpId;
      userData.myJob = job;
    } else {
      userData.myLoja = '';
      userData.erpId = '';
      userData.myJob = '';
    }
    userData.myEmp = {
      id: _id.toString(),
      status,
      name: myEmpData?.name,
      lojas: lojas,
      grupos: grupos,
      groupControl: groupControl,
      dashboards: dashboards,
      indelible: typeof indelible === 'undefined' ? false : indelible,
      config: config
    };
  } catch (error) {
    console.error(error);
    userData.myEmp = '';
  }
  // Caso precise Adiionar amis dados colocar Aqui
  return { isReceived: true, data: userData };
};
export function* parseGetData({ payload }) {
  const { mongoUser } = payload;
  if (mongoUser.logged) {
    const userData = yield call(getUserMongoDB, payload);
    if (userData.isReceived) {
      yield put(
        authStateChanged({
          isAuthenticated: true,
          loading: false,
          user: userData.data,
          myEmp: userData.data.myEmp,
          empId: userData.data.myEmp.id
        })
      );
      // yield put(getEmpMongoDB, userData.data.empId);
    } else {
      // yield put(loginUserError('Erro ao logar no usuário'));
    }
  }
}

export function* handleGetUserData() {
  yield takeEvery(USER_DATA_INIT, parseGetData);
}
// ----------------Obter Minha Empresa-------------------------------------------

const getEmpMongoDB = async ({ mUser, empId }) => {
  try {
    const myEmpData = await mongoGetEmp(mUser.user, empId);
    const { _id, lojas, dashboards, indelible } = myEmpData;
    const myEmp = {
      id: _id.toString(),
      name: myEmpData?.name,
      lojas: lojas,
      dashboards: dashboards,
      indelible: typeof indelible === 'undefined' ? false : indelible
    };
    return { isReceived: true, msg: '', data: myEmp };
  } catch (error) {
    console.error('getEmp', error);
    return { isReceived: false, msg: error, data: {} };
  }
};

export function* parseGetEmpData({ payload }) {
  const { mUser, empId } = payload;
  const userData = yield call(getEmpMongoDB, { mUser, empId });
  if (userData.isReceived) {
    yield put(
      getEmpresaSuccess({
        loading: false,
        myEmp: userData.data
      })
    );
  } else {
    yield put(getUserError({ msg: userData.msg }));
  }
}
export function* handleGetEmpData() {
  yield takeEvery(GET_EMPRESA_INIT, parseGetEmpData);
}
// --------------------------------------------------------

// ----------------------Mudança de Estado de Login----------------------------------
export function* parseAuthStateChange({ payload }) {
  const { isAuthenticated, user } = payload;
  if (isAuthenticated) {
    yield put(listTiposDashInit());
    yield put(listCategoriaDashInit());
    yield put(
      listMyDashsInit({
        mUser: user.mongoUser,
        empId: user.myEmp.id,
        userId: user.id
      })
    );
    // yield put(getEmpMongoDB, userData.data.empId);
  } else {
    // yield put(loginUserError('Erro ao logar no usuário'));
  }
}

export function* handleAuthStateChange() {
  yield takeEvery(AUTH_STATE_CHANGED, parseAuthStateChange);
}

export default function* rootSaga() {
  yield all([
    fork(handleGetUserData),
    fork(handleGetEmpData),
    fork(handleAuthStateChange)
    // fork(watchRegisterUser),
    // fork(watchForgotPassword),
    // fork(watchResetPassword),
  ]);
}
