import React, { useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  IconButton,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  },
  accountLabel: {
    color: theme.palette.primary.black
  },
  iconStyle: {
    marginRight: theme.spacing(2)
  }
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Hidden smDown>
        <Box
          display="flex"
          alignItems="center"
          component={ButtonBase}
          onClick={handleOpen}
          ref={ref}
        >
          {' '}
          <Typography variant="h6" color="primary">
            {user.name}
          </Typography>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="Minha Conta"
            component={RouterLink}
            to="/conta"
          >
            <Typography variant="h6" color="primary">
              {user.name}
            </Typography>
            <AccountCircleIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Minha Conta"
            onClick={handleLogout}
          >
            <Typography variant="h6" color="primary">
              SAIR
            </Typography>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Hidden>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/conta">
          <AccountCircleIcon className={classes.iconStyle} />
          Conta
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon className={classes.iconStyle} /> Sair
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
