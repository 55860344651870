import { User } from '../@types/user';
import { Loja } from '../@types/emp';

interface MenuItens {
  baseUrl: string;
  itens: any[];
  empId: string;
  subHeaderName: string;
  subHeaderIdName: string;
  subItemName: string;
  itemName: string;
}

export const convertArrayToObject = (
  array: any[],
  key: string,
  key2 = ''
): any => {
  const initialValue = {};
  const keyItem = key2 === '' ? key : key2;
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item[keyItem]
    };
  }, initialValue);
};
export const filterUsersEmail = (allUsers: User[]): any => {
  const arrayUsers = allUsers.map(({ email }) => {
    return {
      email: email
    };
  });
  return convertArrayToObject(arrayUsers, 'email');
};

export const listMenuUrl = ({
  baseUrl,
  itens,
  empId,
  subHeaderName,
  subHeaderIdName,
  itemName,
  subItemName
}: MenuItens): { subheader: string; items: any[] }[] => {
  try {
    const itensList: { subheader: string; items: any[] }[] = [];
    // eslint-disable-next-line array-callback-return
    itens.map((item: any) => {
      const tempList = {
        subheader: item[subHeaderName],
        href: `/${baseUrl}/${empId}/${item[subHeaderIdName]}`,
        items: item[itemName].map((sub: any) => {
          return {
            title: sub.name,
            href: `/${baseUrl}/${empId}/${item[subHeaderIdName]}/${sub[subItemName]}`,
            icon: sub.icon
          };
        })
      };
      itensList.push(tempList);
    });
    return itensList;
  } catch {
    return [];
  }
};

type GetNameLoja = {
  lojas: Loja[];
  idLoja: string;
};

export const getNameLoja = ({ lojas, idLoja }: GetNameLoja): string => {
  function searchLojas(arrLoja: Loja): any {
    return arrLoja.id === idLoja;
  }
  const theLoja = lojas.find(searchLojas);
  if (typeof theLoja?.loja !== 'undefined') {
    return theLoja.loja;
  }
  return '';
};
