/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  Collapse
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Logo from '../../../components/Logo';
import useAuth from '../../../hooks/useAuth';
import NavItem from './NavItem';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { listMenuUrl } from '../../../utils/functions';
import SplashScreen from '../../../components/SplashScreen';

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        open={true}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  iconOpenClose: {
    marginTop: 12,
    float: 'right',
    color: theme.palette.text.secondary
  },
  button: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    letterSpacing: 0,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%'
  },
  rodape: {
    paddingTop: theme.spacing(2)
  },
  subHeader: {
    float: 'left'
  },
  subHeaderFont: {
    color: theme.palette.text.secondary
  },
  active: {
    color: `${theme.palette.primary.main}!important`
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const {
    myData,
    myDashs,
    tiposDash,
    categoriasDash,
    empId,
    dashLoading,
    loading,
    isAdmin
  } = useSelector(
    (state) => ({
      isAdmin: state.account.user.isAdmin,
      mongoUser: state.account.user.mongoUser,
      myData: state.account.user,
      loading: state.account.loading,
      myId: state.account.user.id,
      empId: state.account.myEmp.id,
      myDashs: state.dashs.dashs,
      tiposDash: state.dashs.tiposDash,
      categoriasDash: state.dashs.categorias,
      dashLoading: state.dashs.loading
    }),
    shallowEqual
  );
  const [sections, setSection] = useState([]);
  const [open, setOpen] = useState(true);
  const [categoriaPath, setCategoriaPath] = useState('');

  useEffect(() => {
    const sectionsloader = () => {
      if (isMountedRef.current) {
        const fullDash = categoriasDash
          .map((categ) => {
            const cateDash = myDashs
              .map((dash) => {
                if (categ.id === dash.categoriaId) {
                  const myType = tiposDash.filter(
                    (tipo) => tipo.id === dash.tipoId
                  )[0];
                  return {
                    id: dash.id,
                    name: dash.name,
                    icon: typeof myType?.icon !== 'undefined' && myType.icon,
                    url: dash.url
                  };
                }
                return null;
              })
              .filter((dash) => dash !== null);
            if (cateDash.length > 0) {
              return {
                categoria: categ.name,
                categoriaId: categ.id,
                dashs: cateDash
              };
            }
            return null;
          })
          .filter((dash) => dash !== null);
        const lista = listMenuUrl({
          baseUrl: 'dashboard',
          itens: fullDash,
          empId,
          subHeaderName: 'categoria',
          subHeaderIdName: 'categoriaId',
          itemName: 'dashs',
          subItemName: 'id'
        });
        setSection(lista);
      }
    };
    sectionsloader();
  }, [categoriasDash, empId, isMountedRef, myDashs, tiposDash]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const handleOpen = (section) => {
    if (section.href === categoriaPath) {
      setCategoriaPath('/');
    } else {
      setCategoriaPath(section.href);
    }
    setOpen(!open);
  };
  const matchOpen = (href) => {
    return matchPath(categoriaPath, {
      path: href,
      exact: false
    });
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections !== [] &&
            sections.map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <>
                    <Button
                      className={classes.button}
                      fullWidth
                      size="small"
                      onClick={() => handleOpen(section)}
                    >
                      <ListSubheader
                        disableGutters
                        disableSticky
                        className={classes.subHeader}
                      >
                        <Typography
                          align="left"
                          className={classes.subHeaderFont}
                          display="inline"
                          variant="overline"
                        >
                          {section.subheader}
                        </Typography>
                        {'     '}
                      </ListSubheader>
                      {matchOpen(section.href) ? (
                        <ExpandLessIcon className={classes.iconOpenClose} />
                      ) : (
                        <ExpandMoreIcon className={classes.iconOpenClose} />
                      )}
                    </Button>
                  </>
                }
              >
                <Collapse in={matchOpen(section.href)}>
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </Collapse>
              </List>
            ))}
        </Box>
        <Divider />
        <Box className={classes.rodape} display="flex" justifyContent="center">
          <Typography
            align="center"
            display="inline"
            variant="subtitle2"
            color="textSecondary"
          >
            Ambiente: {'  '}
            <Typography
              align="center"
              display="inline"
              variant="subtitle2"
              color="primary"
            >
              {user.myEmp.name}
            </Typography>
          </Typography>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return loading || dashLoading ? (
    <SplashScreen />
  ) : (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
